import { createApp } from 'vue'
import App from './App.vue'
// Antd
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.less'
// router
import router from './router'
//pinia
import pinia from './store'
// ElementPlus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
//Element-svg
import elementIcon from "/src/plugins/svgicon";//实现element-puls icon的办法
import installElementPlus from "/src/plugins/element";//实现element-puls icon的办法
// svg组件
import '@/assets/icons'
import SvgIcon from '@/assets/icons/SvgIcon.vue'
import "@/common/font/font.css";
import VueKinesis from "vue-kinesis";

createApp(App)
    .component('svg-icon', SvgIcon)
    .use(Antd)
    .use(VueKinesis)
    .use(router)
    .use(pinia)
    .use(ElementPlus)
    .use(installElementPlus)
    .use(elementIcon)
    .mount('#app')


    export default {
        mounted() {
          document.addEventListener('click', this.globalClickHandler);
        },
        beforeUnmount() {
          document.removeEventListener('click', this.globalClickHandler);
        },
        methods: {
          globalClickHandler(event) {
            if (event.button === 0) {
              event.preventDefault();
            }
          }
        }
      };
