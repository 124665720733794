import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-035135d3"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["xlink:href", "fill", "fill-opacity", "width", "height"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", {
    class: _normalizeClass($setup.svgClass),
    "aria-hidden": "true"
  }, [_createElementVNode("use", {
    "xlink:href": $setup.iconName,
    fill: $props.color,
    "fill-opacity": $props.fillOpacity,
    width: $props.size,
    height: $props.size
  }, null, 8, _hoisted_1)], 2);
}