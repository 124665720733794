import { computed } from "@vue/reactivity";
export default {
  name: "baseSvgIcon",
  props: {
    iconClass: {
      type: String
    },
    className: {
      type: String
    },
    color: {},
    fillOpacity: {
      type: String,
      default: '1'
    },
    size: {
      type: String,
      default: '36'
    }
  },
  setup(props) {
    const iconName = computed(() => {
      return props.iconClass ? `#icon-${props.iconClass}` : "#icon";
    });
    const svgClass = computed(() => {
      return props.className ? "svg-icon " + props.className : "svg-icon";
    });
    return {
      iconName,
      svgClass
    };
  }
};