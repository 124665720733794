import "core-js/modules/es.array.push.js";
import { onMounted, reactive, toRefs } from 'vue';
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
export default {
  setup() {
    const router = useRouter();
    const data = reactive({
      navList: [{
        title: '解决方案',
        subordinate: ['代驾软件解决方案', '代驾品牌解决方案', '代驾保险解决方案', '代驾票务解决方案', '劳务分发解决方案', '城市运力解决方案', '代驾订单解决方案', '代驾行业物料设计解决方案', '代驾加盟支持解决方案', '新型代驾体系建设解决方案']
      }, {
        title: '合作与生态',
        subordinate: ['合作介绍', '生态讲解']
      }, {
        title: '支持与服务',
        subordinate: ['城市合伙人', '城市运营商', '软件代理商']
      }, {
        title: '了解我们 ',
        subordinate: ['公司介绍']
      }],
      num: 4,
      projectHover: false,
      project1List: [{
        name: '全民代驾',
        img: require('@/assets/product_tab/fleetweet.png')
      }, {
        name: '明日票通',
        img: require('@/assets/product_tab/invoice.png')
      }, {
        name: '代驾管家',
        img: require('@/assets/product_tab/housekeep.png')
      }, {
        name: '司机联盟',
        img: require('@/assets/product_tab/valet_alliance.png')
      }, {
        name: '代驾联盟',
        img: require('@/assets/product_tab/driver_alliance.png')
      }, {
        name: 'T1代驾',
        img: require('@/assets/product_tab/t1.png')
      }],
      project2List: [{
        name: '泛制造业聚合链',
        img: require('@/assets/product_tab/more.png')
      }],
      childLevelNum: '',
      navListTitle: '',
      productStatus: ''
    });
    onMounted(() => {});
    const childLevelItem = (i, index) => {
      data.childLevelNum = data.navList[index].subordinate[i];
      data.navListTitle = data.navList[index].title;
    };
    const projectHoverBut = id => {
      data.num = id;
      if (data.num === 4) {
        return data.projectHover = true;
      }
    };
    const projectMouseleave2 = () => {
      // data.num = 5
    };
    const projectMouseleave = () => {
      data.projectHover = false;
    };
    const replaceNav = index => {
      data.num = index;
      // if(data.num !== 4){

      data.projectHover = false;
      // }
    };

    const jumpPageClick = val => {
      data.navListTitle = val;
      if (val === '开发者') {
        router.push({
          path: '/developer/developer'
        });
      } else if (val === '投资者') {
        router.push({
          path: '/investor/investor'
        });
      } else if (val === '开放平台') {
        router.push({
          path: '/product/fleet-weet'
        });
      } else {
        ElMessage.error('功能维护中...');
      }
      // ElMessage.error('功能开发中，敬请期待')
    };

    const jumpPage = e => {
      data.projectHover = false;
      // data.navListTitle = ''
      // console.log(e);
      let val = judgmentJump(e);
      if (val !== undefined && val !== '') {
        router.push({
          path: val
        });
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }
    };
    const clickProject2 = () => {
      ElMessage.warning('敬请期待');
    };
    const judgmentJump = val => {
      switch (val) {
        case '全民代驾':
          data.navListTitle = '产品';
          return '/product/fleet-weet';
        case '明日票通':
          data.navListTitle = '产品';
          return '/product/tomorrow-invoice';
        case '代驾管家':
          data.navListTitle = '产品';
          return '/product/driving-housekeeper';
        case '司机联盟':
          data.navListTitle = '产品';
          return '/product/driver-union';
        case '代驾联盟':
          data.navListTitle = '产品';
          return '/product/driving-union';
        case 'T1代驾':
          data.navListTitle = '产品';
          return '/product/T1-drive';
        case '软通联合':
          data.navListTitle = '产品';
          return '/product/softcom-union';
        case '代驾软件解决方案':
          return '/software/software-solution';
        case '生态讲解':
          return '/cooperate/ecological-explanation';
        case '合作介绍':
          return '/cooperate/introduction-to-cooperation';
        case '代驾品牌解决方案':
          return '/software/brand-solution';
        case '代驾保险解决方案':
          return '/software/insure-solution';
        case '代驾票务解决方案':
          return '/software/ticket-solution';
        case '代驾订单解决方案':
          return '/software/order-solution';
        case '代驾行业物料设计解决方案':
          return '/software/material-solution';
        case '新型代驾体系建设解决方案':
          return '/software/construction-scheme';
        case '劳务分发解决方案':
          return '/software/labor-services-solution';
        case '代驾加盟支持解决方案':
          return '/software/franchise-solutions';
        case '城市运力解决方案':
          return '/software/capacity-solution';
        case '城市合伙人':
          return '/support-service/city-partner';
        case '城市运营商':
          return '/support-service/city-operator';
        case '软件代理商':
          return '/support-service/software-agent';
        case '公司介绍':
          return '/about-we/company-introduce';
        default:
          ElMessage.error('功能维护中...');
          return;
      }
    };
    return {
      ...toRefs(data),
      replaceNav,
      jumpPage,
      jumpPageClick,
      projectMouseleave,
      projectHoverBut,
      projectMouseleave2,
      clickProject2,
      childLevelItem
    };
  }
};