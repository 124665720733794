import {defineStore} from 'pinia'

export const userStore = defineStore('userStore', {//接收两个参数一个是字符串一个是对象main为起的名字
    state: () => {//定义状态数据
        return {
            name: '',//定义一个状态变量
            phone: '13049433970',//想要隐藏中间四位
            token:''
        }
    },
    getters: {
        phoneHidden(state) {
            return state.phone.toString().replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2')//toString为保证phone为字符串replace进行替换
        },
        getToken:(state)=>state.token,
        actions: {}

    }
})

