import {createRouter,createWebHistory} from "vue-router";
import Home from "@/views/Home.vue";

const routes = [
    // {
    //     path: '/',
    //     redirect: '/dashboard'
    // },
    {
        path: "/",
        name: "Home",
        component: Home,
        redirect: '/',
        children: [
            {
                path: "/",
                name: "",
                meta: {
                    title: '首页'
                },
                component: () => import ( /* webpackChunkName: "dashboard" */ "@/views/dashboard.vue")
            },{
                path: "/product/fleet-weet",
                name: "Product",
                meta: {
                    title: '全民代驾'
                },
                component: () => import ( /* webpackChunkName: "dashboard" */ "@/views/product/fleet-weet.vue")
            },{
                path: "/product/tomorrow-invoice",
                name: "Tomorrow",
                meta: {
                    title: '明日票通'
                },
                component: () => import ( /* webpackChunkName: "dashboard" */ "@/views/product/tomorrow-invoice.vue")
            },{
                path: "/product/driving-housekeeper",
                name: "Driving",
                meta: {
                    title: '代驾管家'
                },
                component: () => import ( /* webpackChunkName: "dashboard" */ "@/views/product/driving-housekeeper.vue")
            },{
                path: "/product/driver-union",
                name: "Driver",
                meta: {
                    title: '司机联盟'
                },
                component: () => import ( /* webpackChunkName: "dashboard" */ "@/views/product/driver-union.vue")
            },{
                path: "/product/T1-drive",
                name: "T1",
                meta: {
                    title: 'T1代驾'
                },
                component: () => import ( /* webpackChunkName: "dashboard" */ "@/views/product/T1-drive.vue")
            },{
                path: "/product/softcom-union",
                name: "softcom-union",
                meta: {
                    title: '软通联合'
                },
                component: () => import ( /* webpackChunkName: "dashboard" */ "@/views/product/softcom-union.vue")
            },{
                path: "/product/driving-union",
                name: "Union",
                meta: {
                    title: '代驾联盟'
                },
                component: () => import ( /* webpackChunkName: "dashboard" */ "@/views/product/driving-union.vue")
            },{
                path: "/software/software-solution",
                name: "Software",
                meta: {
                    title: '代驾软件解决方案'
                },
                component: () => import ( /* webpackChunkName: "dashboard" */ "@/views/software/software-solution.vue")
            },
            {
                path: "/software/brand-solution",
                name: "Brand",
                meta: {
                    title: '代驾品牌解决方案'
                },
                component: () => import ( /* webpackChunkName: "dashboard" */ "@/views/software/brand-solution.vue")
            },{
                path: "/software/insure-solution",
                name: "Insure",
                meta: {
                    title: '代驾保险解决方案'
                },
                component: () => import ( /* webpackChunkName: "dashboard" */ "@/views/software/insure-solution.vue")
            },{
                path: "/software/ticket-solution",
                name: "Ticket",
                meta: {
                    title: '代驾票务解决方案'
                },
                component: () => import ( /* webpackChunkName: "dashboard" */ "@/views/software/ticket-solution.vue")
            },{
                path: "/software/labor-services-solution",
                name: "Labor",
                meta: {
                    title: '劳务分发解决方案'
                },
                component: () => import ( /* webpackChunkName: "dashboard" */ "@/views/software/labor-services-solution.vue")
            },{
                path: "/software/capacity-solution",
                name: "Capacity",
                meta: {
                    title: '城市运力解决方案'
                },
                component: () => import ( /* webpackChunkName: "dashboard" */ "@/views/software/capacity-solution.vue")
            },{
                path: "/software/order-solution",
                name: "Order",
                meta: {
                    title: '代驾订单解决方案'
                },
                component: () => import ( /* webpackChunkName: "dashboard" */ "@/views/software/order-solution.vue")
            },{
                path: "/software/material-solution",
                name: "material-solution",
                meta: {
                    title: '代驾行业物料设计解决方案'
                },
                component: () => import ( /* webpackChunkName: "dashboard" */ "@/views/software/material-solution.vue")
            },{
                path: "/cooperate/ecological-explanation",
                name: "ecologicalExplanation",
                meta: {
                    title: '生态讲解'
                },
                component: () => import ( /* webpackChunkName: "dashboard" */ "@/views/cooperate/ecological-explanation.vue")
            },{
                path: "/cooperate/introduction-to-cooperation",
                name: "introductionToCooperation",
                meta: {
                    title: '合作介绍'
                },
                component: () => import ( /* webpackChunkName: "dashboard" */ "@/views/cooperate/introduction-to-cooperation.vue")
            },{
                path: "/software/construction-scheme",
                name: "construction-scheme",
                meta: {
                    title: '新型代驾体系建设'
                },
                component: () => import ( /* webpackChunkName: "dashboard" */ "@/views/software/construction-scheme.vue")
             },{
                path: "/software/franchise-solutions",
                name: "franchise-solutions",
                meta: {
                    title: '代驾加盟支持解决方案'
                },
                component: () => import ( /* webpackChunkName: "dashboard" */ "@/views/software/franchise-solutions.vue")
            },{
                path: "/support-service/city-partner",
                name: "city-partner",
                meta: {
                    title: '城市合伙人'
                },
                component: () => import ( /* webpackChunkName: "dashboard" */ "@/views/support-service/city-partner.vue")

            },{
                path: "/support-service/city-operator",
                name: "city-operator",
                meta: {
                    title: '城市运营商'
                },
                component: () => import ( /* webpackChunkName: "dashboard" */ "@/views/support-service/city-operator.vue")
            },{
                path: "/support-service/software-agent",
                name: "software-agent",
                meta: {
                    title: '软件代理商'
                },
                component: () => import ( /* webpackChunkName: "dashboard" */ "@/views/support-service/software-agent.vue")
            },{
                path: "/developer/developer",
                name: "developer",
                meta: {
                    title: '开发者'
                },
                component: () => import ( /* webpackChunkName: "dashboard" */ "@/views/developer/developer.vue")
            },{
                path: "/investor/investor",
                name: "investor",
                meta: {
                    title: '投资者'
                },
                component: () => import ( /* webpackChunkName: "dashboard" */ "@/views/investor/investor.vue")
            },{
                path: "/about-we/company-introduce",
                name: "company-introduce",
                meta: {
                    title: '公司介绍'
                },
                component: () => import ( /* webpackChunkName: "dashboard" */ "@/views/about-we/company-introduce.vue")
            },{
                path: '/404',
                name: '404',
                meta: {
                    title: '找不到页面'
                },
                component: () => import (/* webpackChunkName: "404" */ '@/views/404.vue')
            }, {
                path: '/403',
                name: '403',
                meta: {
                    title: '没有权限'
                },
                component: () => import (/* webpackChunkName: "403" */ '@/views/403.vue')
            },{
                path: '*',
                name: '404',
                meta: {
                    title: '找不到页面'
                },
                component: () => import (/* webpackChunkName: "404" */ '@/views/404.vue')
            },
        ]
    },
    {
        path: '/:path(.*)',component: () => import (/* webpackChunkName: "404" */ '@/views/404.vue')
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    next();
});

export default router;
